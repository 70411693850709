<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: page_route ? `${page_route}.detalle` : 'home',
              params: { id: rha_id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${!loading ? `(${evolutions.length})` : ''}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.permissions.rha_evolutions.create" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                color="success"
                @click="formAdd(null)"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </template>
            <span v-text="'Agregar'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <RhaData :rha_id="rha_id" />
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'REGISTROS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th v-text="'Médico'" />
                      <th v-text="'Tipo'" />
                      <th v-text="'Fecha'" />
                      <th v-text="'Descripción'" />
                      <th v-text="'Acción'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in evolutions" :key="i">
                      <td
                        class="font-weight-bold"
                        v-text="evolutions.length - i"
                      />
                      <td
                        v-text="
                          `${item.doctor_name} ${item.doctor_first_surname} ${item.doctor_second_surname}`
                        "
                      />
                      <td v-text="item.doctor_type.rha_evolution_doctor_type" />
                      <td v-text="`${item.date} ${item.time}`" />
                      <td
                        class="text-description"
                        v-text="
                          item.description.length > 300
                            ? `${item.description.substr(0, 300)}...`
                            : item.description
                        "
                      />
                      <td width="190px">
                        <v-tooltip
                          left
                          v-if="login.permissions.rha_evolutions.read"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              color="secondary"
                              dark
                              @click="viewForm(item.id)"
                            >
                              <v-icon v-text="'mdi-eye'" />
                            </v-btn>
                          </template>
                          <span v-text="'Ver'" />
                        </v-tooltip>
                        <v-tooltip
                          left
                          v-if="login.permissions.rha_evolutions.update"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              color="info"
                              dark
                              @click="formEdit(item.id)"
                            >
                              <v-icon v-text="'mdi-pencil'" />
                            </v-btn>
                          </template>
                          <span v-text="'Editar'" />
                        </v-tooltip>
                        <v-tooltip
                          left
                          v-if="login.permissions.rha_evolutions.create"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              color="success"
                              dark
                              @click="formAdd(item)"
                            >
                              <v-icon v-text="'mdi-plus'" />
                            </v-btn>
                          </template>
                          <span
                            v-text="'Agregar nuevo con información de médico'"
                          />
                        </v-tooltip>
                        <v-tooltip
                          left
                          v-if="login.permissions.rha_evolutions.delete"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              color="error"
                              dark
                              @click="desactivate(item.id)"
                            >
                              <v-icon v-text="'mdi-delete'" />
                            </v-btn>
                          </template>
                          <span v-text="'Eliminar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- Dialog STOREUPDATE start -->
    <v-dialog v-model="form_dialog" scrollable persistent max-width="1200px">
      <v-card tile>
        <v-toolbar dark>
          <v-toolbar-title v-text="evolution.id ? 'Editar' : 'Agregar'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="formDialogClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="form_dialog">
          <v-form v-on:submit.prevent ref="form_submit" lazy-validation>
            <v-row>
              <v-col cols="12" class="mt-2">
                <v-card class="mx-auto">
                  <v-toolbar elevation="0">
                    <v-toolbar-title>
                      <h2 class="text-caption" v-text="'MÉDICO'" />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                      <v-tooltip
                        left
                        v-if="login.permissions.rha_evolutions.update"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :disabled="evolution.doctor_id ? false : true"
                            v-on="on"
                            text
                            size="x-small"
                            color="red darken-2"
                            @click="deleteDoctor()"
                          >
                            <v-icon medium v-text="'mdi-delete-empty'" />
                          </v-btn>
                        </template>
                        <span v-text="'Eliminar asignación con BD'" />
                      </v-tooltip>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <small
                          class="font-weight-bold"
                          v-text="'*Pulsa enter para buscar un médico'"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          label="Nombre*"
                          v-model="evolution.doctor_name"
                          :rules="rules.text50Required"
                          type="text"
                          v-on:keyup.enter="doctorSearch"
                          :disabled="evolution.doctor_id ? true : false"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          label="A. paterno"
                          v-model="evolution.doctor_first_surname"
                          :rules="rules.text25"
                          type="text"
                          v-on:keyup.enter="doctorSearch"
                          :disabled="evolution.doctor_id ? true : false"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          label="A. materno"
                          v-model="evolution.doctor_second_surname"
                          :rules="rules.text25"
                          type="text"
                          v-on:keyup.enter="doctorSearch"
                          :disabled="evolution.doctor_id ? true : false"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-select
                          label="Especialidad"
                          v-model="evolution.specialty_type_id"
                          :items="specialty_types"
                          item-value="id"
                          :item-text="(item) => item.specialty_type"
                          :loading="specialty_types_loading"
                          :disabled="evolution.doctor_id ? true : false"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-autocomplete
                          label="Tipo*"
                          v-model="evolution.rha_evolution_doctor_type_id"
                          :rules="rules.required"
                          :items="rha_evolution_doctor_types"
                          item-value="id"
                          :item-text="(item) => item.rha_evolution_doctor_type"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'NOTA DE EVOLUCIÓN'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="6">
                        <DatePicker
                          label="Fecha*"
                          :model.sync="evolution.date"
                          :rules="rules.required"
                          clean
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="evolution.time"
                          :rules="rules.required"
                          label="Hora*"
                          type="time"
                          dense
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="evolution.description"
                          label="Descripción*"
                          :rules="rules.required"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'DOCUMENTOS'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col
                        cols="12"
                        v-for="(document, i) in evolution.documents"
                        :key="i"
                      >
                        <v-row v-if="document.active" dense>
                          <v-col cols="12" sm="12" md="3">
                            <v-autocomplete
                              :label="`Tipo (${i + 1})*`"
                              v-model="document.document_id"
                              :rules="rules.required"
                              :items="documents"
                              item-value="id"
                              :item-text="(item) => item.document"
                              :loading="documents_loading"
                              dense
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="5">
                            <v-text-field
                              label="Descripción"
                              v-model="document.description"
                              type="text"
                              dense
                            />
                          </v-col>
                          <v-col cols="12" xs="12" md="3">
                            <input
                              accept=".pdf, .docx, .doc, .txt, .xlsx, .xls"
                              label="Archivo digital*"
                              type="file"
                              @change="fileSelect($event, i)"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            xs="12"
                            md="1"
                            class="text-center"
                            align-self="center"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  text
                                  icon
                                  x-small
                                  color="error"
                                  @click="fileDelete(i)"
                                >
                                  <v-icon medium v-text="'mdi-close'" />
                                </v-btn>
                              </template>
                              <span v-text="`Eliminar documento (${i + 1})`" />
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          @click="fileAdd"
                          color="warning"
                          :loading="loading"
                          x-small
                          block
                          dark
                        >
                          <v-icon left v-text="'mdi-file-plus'" />
                          Agregar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  :color="evolution.id ? 'info' : 'success'"
                  @click="formSubmit"
                >
                  <v-icon
                    v-text="`mdi-${evolution.id ? 'pencil' : 'plus'}`"
                    left
                  />
                  Aceptar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog STOREUPDATE end -->
    <!-- Dialog VIEW start -->
    <v-dialog v-model="view_dialog" scrollable persistent max-width="1200px">
      <v-card tile>
        <v-toolbar dark>
          <v-toolbar-title v-text="'Ver'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="view_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="view_dialog">
          <v-row>
            <v-col cols="12" class="mt-2">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'MÉDICO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="4">
                      <ViewData label="Nombre" :value="evolution.doctor_name" />
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <ViewData
                        label="A. paterno"
                        :value="evolution.doctor_first_surname"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <ViewData
                        label="A. materno"
                        :value="evolution.doctor_second_surname"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <ViewData
                        label="Especialidad"
                        :value="evolution.specialty_type_name"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <ViewData
                        label="Tipo"
                        :value="evolution.rha_evolution_doctor_type"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'NOTA DE EVOLUCIÓN'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="6">
                      <ViewData label="Fecha" :value="evolution.date" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <ViewData label="Hora" :value="evolution.time" />
                    </v-col>
                    <v-col cols="12">
                      <ViewData
                        label="Descripción"
                        :value="evolution.description"
                        class="text-description"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'DOCUMENTOS'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row
                    dense
                    v-for="(document, i) in evolution.documents"
                    :key="i"
                  >
                    <v-col cols="12" sm="12" md="4">
                      <ViewData
                        :label="`Tipo (${i + 1})`"
                        :value="document.document ? document.document : ''"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <ViewData
                        label="Descripción"
                        :value="document.description"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mr-1"
                            v-on="on"
                            fab
                            x-small
                            color="warning"
                            dark
                            :href="
                              url_documents + '/rha_evolutions/' + document.url
                            "
                            target="_blank"
                          >
                            <v-icon v-text="'mdi-download'" />
                          </v-btn>
                        </template>
                        <span v-text="'Descargar'" />
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog VIEW end -->
    <!-- Dialog DOCTORS start -->
    <v-dialog v-model="dialog_doctor" scrollable persistent max-width="1000px">
      <v-card tile>
        <v-toolbar dark>
          <v-toolbar-title v-text="'Busqueda de médicos'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="dialog_doctor = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th v-text="'Médico'" />
                      <th v-text="'Acción'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in doctors" :key="i">
                      <td class="font-weight-bold" v-text="i + 1" />
                      <td
                        v-text="
                          `${item.name} ${item.first_surname} ${item.second_surname}`
                        "
                      />
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="warning"
                              @click="doctorSelected(i)"
                            >
                              <v-icon medium v-text="'mdi-check'" />
                            </v-btn>
                          </template>
                          <span v-text="`Seleccionar médico (${i + 1})`" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog DOCTORS end -->
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  URL_DOCUMENTS,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { storeUpdate, show, index, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import DatePicker from "../../components/DatePicker.vue";
import RhaData from "../../components/RhaData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    DatePicker,
    RhaData,
    FaqDlg,
  },
  data() {
    return {
      page_route: null,
      url_documents: URL_DOCUMENTS,
      login: this.$store.getters.getLogin,
      rha_id: this.$attrs.id,
      loading: true,
      evolution: {},
      rules: rules(),
      form_dialog: false,
      view_dialog: false,
      dialog_doctor: false,
      date_picker: false,
      evolutions: [],
      doctors: [],
      doctors_loading: true,
      specialty_types: [],
      specialty_types_loading: true,
      rha_evolution_doctor_types: [],
      rha_evolution_doctor_types_loading: true,
      documents: [],
      documents_loading: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getEvolutions() {
      this.loading = true;

      index("rha/evolution", this.login.token, [
        {
          name: "active",
          value: 1,
        },
        {
          name: "id",
          value: this.login.id,
        },
        {
          name: "rha_id",
          value: this.rha_id,
        },
      ]).then((response) => {
        this.evolutions = response.data;
        this.loading = false;
      });
    },
    defaultData(item) {
      this.evolution = {
        created_by_id: this.login.id,
        updated_by_id: this.login.id,
        rha_id: this.$attrs.id,
        doctor_id: null,
        doctor_name: "",
        doctor_first_surname: "",
        doctor_second_surname: "",
        specialty_type_id: null,
        rha_evolution_doctor_type_id: null,
        documents: [],
      };

      if (item) {
        this.evolution.doctor_id = item.doctor_id;
        this.evolution.doctor_name = item.doctor_name;
        this.evolution.doctor_first_surname = item.doctor_first_surname;
        this.evolution.doctor_second_surname = item.doctor_second_surname;
        this.evolution.specialty_type_id = item.specialty_type_id;
        this.evolution.rha_evolution_doctor_type_id =
          item.rha_evolution_doctor_type_id;
      }
    },
    formAdd(item) {
      console.log(item);
      this.defaultData(item);
      this.form_dialog = true;
    },
    formEdit(id) {
      show("rha/evolution", this.login.token, id).then((response) => {
        this.evolution = response;
        this.form_dialog = true;
      });
    },
    formDialogClose() {
      this.form_dialog = false;
      this.defaultData(null);
      this.$refs.form_submit.resetValidation();
    },
    viewForm(id) {
      show("rha/evolution", this.login.token, id).then((response) => {
        this.evolution = response;
        this.view_dialog = true;
      });
    },
    doctorSearch() {
      const search_doctor = {
        name: this.evolution.doctor_name,
        first_surname: this.evolution.doctor_first_surname,
        second_surname: this.evolution.doctor_second_surname,
      };
      Axios.post(
        URL_API + "/doctors/search",
        search_doctor,
        headersToken(this.login.token)
      ).then((response) => {
        this.doctors = response.data.data;
        if (response.data.data.length === 1) {
          this.evolution.doctor_name = response.data.data[0].name;
          this.evolution.doctor_first_surname =
            response.data.data[0].first_surname;
          this.evolution.doctor_second_surname =
            response.data.data[0].second_surname;
          this.evolution.specialty_type_id =
            response.data.data[0].specialty_type_id;
        } else {
          if (response.data.data.length === 0) this.search_fail = true;
          else {
            this.dialog_doctor = true;
          }
        }
      });
    },
    doctorSelected(i) {
      this.evolution.doctor_id = this.doctors[i].id;
      this.evolution.doctor_name = this.doctors[i].name;
      this.evolution.doctor_first_surname = this.doctors[i].first_surname;
      this.evolution.doctor_second_surname = this.doctors[i].second_surname;
      this.evolution.specialty_type_id = this.doctors[i].specialty_type_id;
      this.dialog_doctor = false;
    },
    deleteDoctor() {
      this.evolution.doctor_id = "";
      this.evolution.doctor_name = "";
      this.evolution.doctor_first_surname = "";
      this.evolution.doctor_second_surname = "";
      this.evolution.specialty_type_id = "";
      this.$forceUpdate();
    },
    fileSelect(event, i) {
      this.evolution.documents[i].file = event.target.files[0];
    },
    fileAdd() {
      this.evolution.documents.push({
        id: null,
        document_id: 0,
        description: "",
        active: true,
      });
    },
    fileDelete(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el documento (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.evolution.documents[i].id === null)
              this.evolution.documents.splice(i, 1);
            else this.evolution.documents[i].active = false;
          }
        });
    },
    formSubmit() {
      if (this.$refs.form_submit.validate()) {
        let documents_exist = true;
        let i = 0;

        if (!this.evolution.id && this.evolution.documents.length > 0) {
          for (i = 0; i < this.evolution.documents.length; i++) {
            if (typeof this.evolution.documents[i].file !== "object") {
              documents_exist = false;
            }
          }
        }

        if (documents_exist) {
          this.$swal
            .fire(
              msgConfirm(
                `¿Confirma ${
                  this.evolution.id ? "editar" : "agregar"
                } el registro?`
              )
            )
            .then((response) => {
              if (response.isConfirmed) {
                this.loading = true;

                storeUpdate(
                  "rha/evolution",
                  this.login.token,
                  this.id,
                  this.evolution,
                  this.login.id
                ).then((response) => {
                  this.$swal.fire(
                    msgAlert(
                      response.success ? "success" : "error",
                      response.message
                    )
                  );

                  if (response.success) {
                    this.formDialogClose();
                    this.getEvolutions();
                  } else {
                    console.log(response.message);
                  }
                });
              }
            });
        } else {
          this.$swal.fire(
            msgAlert(
              "error",
              `No se ha seleccionado archivo para el documento(${i})`
            )
          );
        }
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    desactivate(id) {
      this.$swal
        .fire(msgConfirm("Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy("rha/evolution", this.login.token, id, this.login.id).then(
              (response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.getEvolutions()
                  : console.log(response.message);
              }
            );
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route = this.$route.name;
    this.page_route = this.page_route.split(".");
    this.page_route = this.page_route[0];

    index("documents/3", this.login.token, []).then((response) => {
      this.documents = response.data;
      this.documents_loading = false;
    });
    catalog("specialty_types", this.login.token).then((response) => {
      this.specialty_types = response;
      this.specialty_types_loading = false;
    });
    catalog("rha_evolution_doctor_types", this.login.token).then((response) => {
      this.rha_evolution_doctor_types = response;
      this.rha_evolution_doctor_types_loading = false;
    });

    this.getEvolutions();
  },
};
</script>

<style>
.text-description {
  white-space: pre-line;
}
</style>